class Language {
  id;
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.traduction = data.traduction;
  }
}
var DataConverter = {
  toFirestore: function(rate) {
    return {
      adult : rate.adult,
      elderly : rate.elderly,
      child : rate.child,
      infant : rate.infant,
    }
  },
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    data.id = snapshot.id
    return new Language(data)
  }
}
export {Language, DataConverter} 