import BaseModel from './BaseModel';
//import Vue from 'vue';
//import lodash from 'lodash';
import {DataConverter as LangDataConverter} from '@/FirestoreDataConverters/Language.js';


export default class Language extends BaseModel{
  
  constructor(options) {
    super(options);
  }
  getAll() {
    return this.db.collection('languages').withConverter(LangDataConverter);
  }
}