import BaseModel from './BaseModel';
import firebase from 'firebase/app';
import 'firebase/storage'
import {
    DataConverter as CuponDataConverter,
    Cupones as CuponConverter
} from '@/FirestoreDataConverters/Cupones.js';

export default class Cupones extends BaseModel {
  constructor(options) {
    super(options);
  }
  async addCupon(obj) {
    if (obj.type == 'tour') {
      await this.db.collection('cupones')
      .where('idTour', '==', obj.idTour)
      .where('code', "==", obj.code.toUpperCase())
      .get()
      .then(snap => {
        if (!snap.empty) {
          throw `Error el codigo ${obj.code} ya esta registrado`;
        }
      })
    } else {
      await this.db.collection('cupones')
      .where('type', '==', 'extra')
      .where('code', "==", obj.code.toUpperCase())
      .get()
      .then(snap => {
        if (!snap.empty) {
          throw `Error el codigo ${obj.code} ya esta registrado`;
        }
      })
    }
    return this.db.collection('cupones').withConverter(CuponDataConverter).add(new CuponConverter(obj));
  }
  addLenguDesc(ref, leng, obj) {
    return ref.collection('cuponesLanguages').doc(leng).set(obj);
  }
  all() {
    return this.db.collection('cupones').withConverter(CuponDataConverter);
  }
  update(id, obj) {
    return this.db.collection('cupones').doc(id).update(obj);
  }
  delete(id) {
    return new Promise((resolve, reject) => {
      this.db.collection('cupones').doc(id).get().then(snapDoc => {
        let batch = this.db.batch();
        let promises = [];
        promises.push(snapDoc.ref.collection('cuponesLanguages').get())
        promises.push(snapDoc.ref.collection('cuponesImages').get())
        Promise.all(promises).then(values => {
          let languages = values[0];
          let images = values[1];
          languages.forEach(doc => {
            batch.delete(this.db.collection('cupones').doc(id).collection('cuponesLanguages').doc(doc.id));
          })
          let promisesImages = [];
          images.forEach(doc => {
            promisesImages.push(firebase.storage().ref(doc.data().fullpath).delete())
            batch.delete(this.db.collection('cupones').doc(id).collection('cuponesImages').doc(doc.id));
          })
          Promise.all(promisesImages).then(() => {
            batch.delete(snapDoc.ref);
            batch.commit().then(() => resolve('doing'))
                .catch(error => reject(error))
          })
          .catch(error => reject(error))
        })
        .catch(error => reject(error))
      })
    })
  }
  findByCode(code) {
    return this.db.collection('cupones').where('code', '==', code);
  }
  findByCodeAndTourId(code, tourId) {
    return this.db.collection('cupones').where('code', '==', code).where('idTour', '==', tourId);
  }
  findByCupon(id) {
    return this.db.collection('cupones').doc(id).withConverter(CuponDataConverter);
  }
  findCuponTourNacional(tour) {
    return this.db.collection("cupones").where("tour", "==", tour).where("national", "==", "si");
  }
  searchCodeCupon(code) {
      return this.db.collection("cupones").where("code", "==", code);
  }
  /*searchCuponEndDAte(docDataCupon, dateStart) {
    if (docDataCupon.type == 'extra') {}return this.db.collection("cupones")
    .where("type", "==", docDataCupon.type)
    .where("dateEnd", ">=", dateStart)
    .orderBy('dateEnd', 'desc')
    .withConverter(CuponDataConverter);
  }*/
  searchCuponesAfterEndDate(tourId, date) {
    return this.db.collection('cupones').where('dateEnd', '>=', date).where('idTour', '==', tourId).orderBy('dateEnd', 'desc').withConverter(CuponDataConverter);
  }
}