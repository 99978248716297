<template>
  <div>
    <portal to="title-page">
      <h1>Crear cupon</h1>
    </portal>
    <div>
      <v-card class="mx-auto elevation-1">
        <v-card-title>
          <v-icon large left>mdi-plus</v-icon>
          <span class="title font-weight-light">Crear cupon</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="validForm" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Idioma"
                  :prepend-icon="mdiFlag"
                  :value="language ? language.name : ''"
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Type tour nacional -->
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                  v-model="cuponData.type"
                  :items="typeCupon"
                  :prepend-icon="mdiText"
                  item-text="text"
                  item-value="type"
                  :rules="[v => !!v || 'Clasifica el cupon']"
                  label="Seleccione el tipo de cupon"
                  required
                  dense
                  v-on:change="changeSelecType()"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-if="tours != null"
                  v-model="cuponData.tour"
                  :items="tours"
                  :prepend-icon="mdiPackageVariantClosed"
                  item-text="name"
                  item-value="id"
                  :rules="[!(cuponData.type == 'tour') || rules.requiredTour]"
                  label="Tour"
                  required
                  dense
                  v-on:change="changeSelecTour()"
                  v-show="!disabledTour"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="cuponData.nacional"
                  :items="typeNacional"
                  :prepend-icon="mdiText"
                  item-text="text"
                  item-value="type"
                  :rules="[!(cuponData.type == 'tour') || rules.required]"
                  label="¿Aplica para tarifa nacional?"
                  required
                  dense
                  v-on:change="changeSelecNacional()"
                  v-show="!disabledNacional"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Nombre"
                  :prepend-icon="mdiText"
                  color="primary"
                  v-model="cuponData.nombreCupon"
                  required
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Codigo del cupon"
                  :prepend-icon="mdiTicket"
                  color="primary"
                  v-model="cuponData.codigoCupon"
                  required
                  :rules="[rules.required, rules.min/*, !validCodeCupon == true || 'Este codigo ya existe' */]"
                  dense
                ></v-text-field>
                  <!-- v-on:change="validarCodigoCupon()" -->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Descuento Nacionales"
                  :prepend-icon="mdiPercent"
                  type="number"
                  color="primary"
                  v-model="cuponData.porcentNacional"
                  required
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Descuento Locales"
                  :prepend-icon="mdiPercent"
                  type="number"
                  color="primary"
                  v-show="!disableLocalPercent"
                  v-model="cuponData.porcentLocal"
                  required
                  :rules="[cuponData.nacional == 'si' || rules.required]"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Descuento Extranjeros"
                  :prepend-icon="mdiPercent"
                  type="number"
                  v-show="!disableForeignPercent"
                  color="primary"
                  v-model="cuponData.porcentExtranjero"
                  required
                  :rules="[cuponData.nacional == 'si' || rules.required]"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-menu
                  class="model"
                  v-model="menupicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  dense
                  v-show="!disabledfecha"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      readonly
                      label="Periodo de fechas"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      :rules="[cuponData.nacional == 'si' || rules.required, cuponData.nacional == 'si' || rules.rangeDates]"
                      dense
                      v-show="!disabledfecha"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-show="!disabledfecha" v-model="cuponData.dates" range no-title></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p>Descripción</p>
                <vue-editor
                  color="primary"
                  :editor-toolbar="customToolbar"
                  v-model="descripcionEsp"
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                  dense
                ></vue-editor>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  :disabled="disableBtnAdd"
                  color="primary"
                  class="mr-4"
                  @click="validateForm()"
                >
                  <v-icon>{{mdiContentSave}}</v-icon> Guardar
                </v-btn>
              </v-col>
              <!-- <v-col v-if="this.cuponData.type == 'tour'">
                <v-btn
                  :disabled="!validForm || cuponData.type == '' || cuponData.tour == '' || cuponData.nacional == '' || cuponData.nombreCupon == '' || cuponData.codigoCupon == '' || cuponData.nationalPercent == '' || descripcionEsp == '' || validCodeCupon == true"
                  color="primary"
                  class="mr-5"
                  @click="validateForm"
                >Agregar</v-btn>
              </v-col>
              <v-col v-else-if="this.cuponData.type == 'extra'">
                <v-btn
                  :disabled="!validForm || cuponData.type == '' || cuponData.nombreCupon == '' || cuponData.codigoCupon == '' || cuponData.porcentNacional == '' || cuponData.porcentLocal == '' ||cuponData.porcentExtranjero == '' || descripcionEsp == '' || validCodeCupon == true "
                  color="primary"
                  class="mr-5"
                  @click="validateForm"
                  >Agregar
                </v-btn>
              </v-col> -->
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="-1">
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn color text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import {
  mdiPackageVariantClosed,
  mdiClose,
  mdiFlag,
  mdiText,
  mdiTicketPercentOutline,
  mdiPercent,
  mdiContentSave,
} from "@mdi/js";
import { VueEditor } from "vue2-editor";
import Language from "@/models/Language.js";
import Cupones from "@/models/Cupones.js";
import Tours from "@/models/Tour.js";
import firebase from "firebase/app";
import "firebase/firestore";
export default {
  name: "CuponesCreate",
  components: { VueEditor },
  data() {
    return {
      /******* icons ***********/
      mdiClose: mdiClose,
      mdiFlag: mdiFlag,
      mdiText: mdiText,
      mdiTicket: mdiTicketPercentOutline,
      mdiPercent: mdiPercent,
      mdiPackageVariantClosed: mdiPackageVariantClosed,
      mdiContentSave: mdiContentSave,
      /***********************/
      CuponesModel: new Cupones(),
      languageModel: new Language(),
      ToursModel: new Tours(),
      menupicker: false,
      disableBtnAdd: false,
      language: null,
      descripcionEsp: "",
      cuponesLanguages: "",
      cuponData: {
        type: "",
        tour: null,
        nacional: "",
        nombreCupon: "",
        codigoCupon: "",
        fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
        dates: ["", ""],
        porcentNacional: 0,
        porcentLocal: 0,
        porcentExtranjero: 0,
        status: "inactive",
      },
      typeCupon: [
        { type: "tour", text: "tour" },
        { type: "extra", text: "servicio extra" },
      ],
      typeNacional: [],
      rules: {
        required: (v) => (v && v.length > 0) || "Campo requerido",
        min: (v) => v.length >= 4 || "Min 4 caracteres",
        rangeDates: (v) => {
          let dates = v.split(" ~ ");
          if (dates.length == 1) {
            return "Ingrese la fecha de fin";
          }
          let start = this.$moment(dates[0]);
          let end = this.$moment(dates[1]);
          if (end <= start) {
            return "La fecha de fin debe ser mayor a la fecha de inicio";
          }
          return true;
        },
        requiredTour: (v) => {
          if (!v) {
            return "Seleccione un tour";
          }
          return true;
        },
      },
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      validForm: true,
      snackbar: {
        show: false,
        color: "",
        message: "",
      },
      validCodeCupon: [],
      validNacional: [],
      valueNacional: false,
      tours: null,
      disabledTour: false,
      disabledNacional: false,
      disabledfecha: false,
      disableLocalPercent: false,
      disableForeignPercent: false,
      changeType: null,
      changeTour: null,
      changeNacional: null,
      validarFechas: [],
    };
  },
  methods: {
    async validateForm() {
      if (this.$refs.form.validate()) {
        let loading = this.$loading.show();
        let startDateMoment;
        let startFormatDate;
        let endDateMoment;
        let endFormatDate;

        if (this.cuponData.type == "tour") {
          if (this.cuponData.nacional == "no") {
            startDateMoment = this.$moment(this.cuponData.dates[0]);
            startFormatDate = firebase.firestore.Timestamp.fromDate(startDateMoment.toDate());
            endDateMoment = this.$moment(this.cuponData.dates[1]);
            endFormatDate = firebase.firestore.Timestamp.fromDate(endDateMoment.toDate());
          } else if (this.cuponData.nacional == "si") {
            startFormatDate = "";
            endFormatDate = "";
          }
        } else if (this.cuponData.type == "extra") {
          // si es extra no hay datos en  tourId ni en nombreTour
          this.cuponData.tour.id = null;
          this.cuponData.tour.name = null;
          this.cuponData.nacional = "no";
          // se le da formato a la fecha
          startDateMoment = this.$moment(this.cuponData.dates[0]);
          startFormatDate = firebase.firestore.Timestamp.fromDate(startDateMoment.toDate());
          endDateMoment = this.$moment(this.cuponData.dates[1]);
          endFormatDate = firebase.firestore.Timestamp.fromDate(endDateMoment.toDate());
        }
        var docDataCupon = {
          type: this.cuponData.type,
          idTour: this.cuponData.tour.id,
          tour: this.cuponData.tour.name,
          national: this.cuponData.nacional,
          name: this.cuponData.nombreCupon,
          code: this.cuponData.codigoCupon.toUpperCase(),
          percent: this.cuponData.porcentajeCupon,
          nationalPercent: this.cuponData.porcentNacional,
          localPercent: this.cuponData.porcentLocal,
          foreignPercent: this.cuponData.porcentExtranjero,
          dateStart: startFormatDate,
          dateEnd: endFormatDate,
          dateCreate: this.cuponData.fechaCreacion,
          status: this.cuponData.status,
        };
        var docLengDesc = {
          name: this.cuponData.nombreCupon,
          description: this.descripcionEsp,
          language: this.languages.es.name,
        };
        this.CuponesModel.addCupon(docDataCupon)
        .then((docRef) => {
          this.CuponesModel.addLenguDesc(
            docRef,
            this.cuponesLanguages,
            docLengDesc
          ).then(() => {
            loading.hide();
            this.snackbar.show = true;
            this.snackbar.color = "green";
            this.snackbar.message = `El cupon se agrego correctamente `;
            this.$router.push({ path: "/backend/admin/cupones" });
          });
        })
        .catch((error) => {
          loading.hide();
          console.log(error);
          this.snackbar.show = true;
          this.snackbar.color = "red";
          this.snackbar.message = error;
        });
      } else {
        console.log('error form');
      }
    },
    changeSelecType() {
      // console.log('tour', this.cuponData.tour);
      if (this.cuponData.type == "tour") {
        console.log("tour");
        this.disabledTour = false;
        this.disabledNacional = false;
        this.changeType = this.cuponData.type;
        // al cambiar type cupon se limpia tour y nacional
      } else if (this.cuponData.type == "extra") {
        console.log("extra");
        this.disabledTour = true;
        this.disabledNacional = true;
        this.disabledfecha = false;
        this.changeType = this.cuponData.type;
        this.disableLocalPercent = false;
        this.disableForeignPercent = false;
        // al cambiar type cupon se limpia tour y nacional
      }
    },
    async changeSelecTour() {
      this.changeTour = this.cuponData.tour;
      let loading = this.$loading.show();
      await this.findNacion(this.changeTour);
      loading.hide()
      // despues de cambiar el tour limpiamos campo nacional
      this.cuponData.nacional = '';
      this.disableLocalPercent = false;
      this.disableForeignPercent = false;
    },
    changeSelecNacional() {
      this.changeNacional = this.cuponData.nacional;
      if (this.changeNacional == "no") {
        this.disabledNacional = false;
        this.disableLocalPercent = false;
        this.disableForeignPercent = false;
        this.disabledfecha = false;
      } else {
        this.disabledfecha = true;
        this.disableLocalPercent = true;
        this.disableForeignPercent = true;
      }
    },
    // comprobar si existe el codigo del cupon
    /*validarCodigoCupon() {
      let code;
      code = this.cuponData.codigoCupon;
      this.CuponesModel.searchCodeCupon(code).onSnapshot(snap => {
        let resp = snap;
        if (resp.empty) {
          // no existe cupon con ese nombre
          this.validCodeCupon = false;
        } else {
          // ya existe ese nombre
          this.validCodeCupon = true;
          this.snackbar.show = true;
          this.snackbar.color = "red";
          this.snackbar.message = `Error este codigo ya existe`;
        }
      });
    },*/
    async findNacion(selecTour) {
      await this.CuponesModel.findCuponTourNacional(selecTour.name)
        .get()
        .then((snap) => {
          let resp = snap;
          if (resp.empty) {
            //No existe cupon nacional
            this.typeNacional = [
              { type: "si", text: "si" },
              { type: "no", text: "no" },
            ];
          } else {
            // ya existe cupon nacional
            this.typeNacional = [{ type: "no", text: "no" }];
          }
        });
    },
    showSnackbarError(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = "red";
    },
    showSnackbarSuccess(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = "green";
    },
  },
  computed: {
    dateRangeText() {
      let datesStr = this.cuponData.dates.join(" ~ ");
      if (datesStr == " ~ ") {
        return "";
      }
      return datesStr;
    },
  },
  mounted() {
    this.languageModel.getAll().onSnapshot((snap) => {
      this.languages = {};
      snap.forEach((doc) => {
        this.languages[doc.id] = doc.data();
      });
      this.language = this.languages.es;
      this.cuponesLanguages = "es";
      // console.log(this.cuponesLanguages);
    });
    this.ToursModel.all().onSnapshot((snap) => {
      this.tours = [];
      snap.forEach((doc) => {
        this.tours.push({ id: doc.data().id, name: doc.data().name });
      });
    });
  },
  created() {
    this.$store.commit("SET_LAYOUT", "admin");
  },
};
</script>
