class Cupones {
  moment;
  #ref;
  constructor(obj) {
    this.id = obj.id;
    this.type = obj.type;
    this.idTour = obj.idTour;
    this.tour = obj.tour;
    this.national = obj.national;
    this.name = obj.name;
    this.code = obj.code;
    this.localPercent = obj.localPercent;
    this.nationalPercent = obj.nationalPercent;
    this.foreignPercent = obj.foreignPercent;
    this.dateStart = obj.dateStart;
    this.dateEnd = obj.dateEnd;
    this.dateCreate = obj.dateCreate;
    this.status = obj.status;
  }
  set moment(moment) {
      this.moment = moment;
  }
  set ref(ref) {
      this.#ref = ref;
  }
  formatStartDate(format) {
    format = format || 'DD-MM-YYYY'
    return this.moment(this.dateStart.toDate()).format(format);
  }
  formatEndDate(format) {
    format = format || 'DD-MM-YYYY'
    return this.moment(this.dateEnd.toDate()).format(format);
  }
}

var DataConverter = {
    toFirestore: function(cupones) {
        return {
            type: cupones.type,
            idTour: cupones.idTour,
            tour: cupones.tour,
            national: cupones.national,
            name: cupones.name,
            code: cupones.code,
            localPercent: cupones.localPercent,
            nationalPercent: cupones.nationalPercent,
            foreignPercent: cupones.foreignPercent,
            dateStart: cupones.dateStart,
            dateEnd: cupones.dateEnd,
            dateCreate: cupones.dateCreate,
            status: cupones.status,
        }
    },
    fromFirestore: function(snapshot, options) {
        const data = snapshot.data(options);
        data.id = snapshot.id
        return new Cupones(data)
    }
}
export {
    Cupones,
    DataConverter
}